.btn {
  border: none;
  background: none;

  display: block;
  padding: 16px 36px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  line-height: 1.22;
  border-radius: 6px;
  width: fit-content;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  font-weight: var(--font-weight-bold);
  line-height: 1.22; /* 19.5px */
}

.btn--orange {
  color: var(--color-white);
  background-color: var(--color-accent);
}

.btn--white {
  color: var(--color-primary-brown);
  background-color: var(--color-white);
}

.btn--orange:hover {
  background-color: var(--color-accent-dark);
}

.btn--orange:focus-visible {
  background-color: var(--color-accent-dark);
}

.btn--white:hover {
  color: var(--color-white);
  border-color: var(--color-white);
  background-color: transparent;
}

.btn--white:focus-visible {
  color: var(--color-white);
  border-color: var(--color-white);
  background-color: transparent;
}

.btn--disabled {
  pointer-events: none;
  opacity: 0.5;
}

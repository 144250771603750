/* Modal */
@keyframes fadeIn {
	0% {
		background: rgba(0, 0, 0, 0);
		backdrop-filter: none;
	}
	100% {
		background: var(--color-black-60);
		backdrop-filter: blur(3px);
	}
}

@keyframes fadeInFull {
	0% {
		background: rgba(0, 0, 0, 0);
	}
	100% {
		background: var(--color-black);
	}
}

@keyframes fadeOut {
	0% {
		background: var(--color-black-60);
		backdrop-filter: blur(3px);
	}
	100% {
		background: rgba(0, 0, 0, 0);
		backdrop-filter: none;
	}
}

@keyframes scaleUpMoveDown {
	0% {
		transform: scale(0.8) translateY(-1000px);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
	}
}

@keyframes scaleDownMoveTop {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 1;
	}
	100% {
		transform: scale(0.8) translateY(-1000px);
		opacity: 0;
	}
}

@keyframes quickScaleDown {
	0% {
		transform: scale(1);
	}
	99.9% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

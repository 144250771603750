.color-brand-green {
  color: var(--color-brand-green);
}

.color-accent {
  color: var(--color-accent);
}

.color-accent-dark {
  color: var(--color-accent-dark);
}

.color-primary-brown {
  color: var(--color-primary-brown);
}

.color-background-green {
  color: var(--color-background-green);
}

.color-background-grey {
  color: var(--color-background-grey);
}

.color-red {
  color: var(--color-grey);
}

.color-black {
  color: var(--color-black);
}

.color-white {
  color: var(--color-white);
}

.box-shadow {
  box-shadow: 0px 0.57px 2.12px rgba(69, 69, 92, 0.028), 0px 0px 0px 0.5px #eeeeee, 0px 7px 26px rgba(69, 69, 92, 0.07),
    0px 2.7px 8.28px rgba(69, 69, 92, 0.043);
}

.box-shadow:hover {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(69, 69, 92, 0.07), 0px 12.71px 25.48px rgba(69, 69, 92, 0.043),
    0px 2.69px 6.52px rgba(69, 69, 92, 0.028);
}

.box-shadow:focus-visible {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(69, 69, 92, 0.07), 0px 12.71px 25.48px rgba(69, 69, 92, 0.043),
    0px 2.69px 6.52px rgba(69, 69, 92, 0.028);
}

.br-1 {
  border-radius: 4px;
}

.br-2 {
  border-radius: 8px;
}

.border-top {
  border-top-style: solid;
  border-image: url('../../icons/custom-border.svg') 2 round;
}

.border-bottom {
  border-bottom-style: solid;
}

.border-spacing-bottom {
  border-spacing: 10px 12px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-offset {
  text-underline-offset: 4px;
}

@media screen and (min-width: 576px) {
  .text-sm-center {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .text-md-center {
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .text-lg-center {
    text-align: center;
  }
}

.text-start {
  text-align: start;
}

@media screen and (min-width: 768px) {
  .text-md-start {
    text-align: start;
  }
}

@media screen and (min-width: 992px) {
  .text-lg-start {
    text-align: start;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.hover--opacity {
  transition: opacity 0.3s;
}

.hover--opacity:hover {
  opacity: 0.8;
}

.hover--opacity:focus-visible {
  opacity: 0.8;
}

.text-linethrough {
  text-decoration: line-through;
}

.w-100 {
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
}

.w-fit-content {
  width: fit-content !important;
}

@media screen and (min-width: 768px) {
  .w-md-fit-content {
    width: fit-content !important;
  }
}

@media screen and (min-width: 992px) {
  .w-lg-fit-content {
    width: fit-content !important;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-fit-content {
    width: fit-content !important;
  }
}

.mb-minus-2 {
  margin-bottom: -8px;
}

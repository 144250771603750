.card {
  padding: 40px;
  height: auto;
  border-radius: 8px;
  background-color: white;
  transition: box-shadow 0.3s;
  box-shadow: 0px 0.57px 2.12px rgba(69, 69, 92, 0.028), 0px 0px 0px 0.5px #eeeeee, 0px 7px 26px rgba(69, 69, 92, 0.07), 0px 2.7px 8.28px rgba(69, 69, 92, 0.04);
}

.card:hover {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(69, 69, 92, 0.07), 0px 12.71px 25.48px rgba(69, 69, 92, 0.04),
    0px 2.69px 6.52px rgba(69, 69, 92, 0.028);
}

.card:focus-visible {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(69, 69, 92, 0.07), 0px 12.71px 25.48px rgba(69, 69, 92, 0.04),
    0px 2.69px 6.52px rgba(69, 69, 92, 0.028);
}

.card--positive {
  box-shadow: 0px 0.57px 2.12px rgba(188, 207, 96, 0.102), 0px 0px 0px 0.5px #eeeeee, 0px 7px 26px rgba(188, 207, 96, 0.28),
    0px 2.7px 8.28px rgba(188, 207, 96, 0.16);
}

.card--positive:hover {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(188, 207, 96, 0.28), 0px 12.71px 25.48px rgba(188, 207, 96, 0.16),
    0px 2.69px 6.52px rgba(188, 207, 96, 0.102);
}

.card--positive:focus-visible {
  box-shadow: 0px 0px 0px 0.5px #eeeeee, 0px 33px 80px rgba(188, 207, 96, 0.28), 0px 12.71px 25.48px rgba(188, 207, 96, 0.16),
    0px 2.69px 6.52px rgba(188, 207, 96, 0.102);
}

.card--feature {
  min-height: 450px;
}

@media screen and (min-width: 576px) {
  .card--feature {
    min-height: unset;
  }
}

@media screen and (min-width: 1200px) {
  .card--feature {
    width: 360px;
    min-height: 450px;
  }
}

@media screen and (min-width: 1400px) {
  .card--feature {
    width: 410px;
    height: 520px;
  }
}

.card--review {
  display: flex;
  flex-direction: column;
  max-width: unset;
}

@media screen and (min-width: 576px) {
  .card--review {
    max-width: unset;
  }
}

@media screen and (min-width: 768px) {
  .card--review {
    max-width: unset;
  }
}

@media screen and (min-width: 992px) {
  .card--review {
    max-width: 460px;
  }
}

@media screen and (min-width: 1200px) {
  .card--review {
    max-width: 360px;
  }
}

@media screen and (min-width: 1400px) {
  .card--review {
    max-width: 420px;
  }
}

.card--review .card__text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

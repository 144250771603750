.input {
	font-weight: var(--font-weight-regular);
	font-size: 18px;
	line-height: 1.44; /* 26px */
	padding: 8px 12px;
	background: var(--color-background-grey);
	border: none;
	border-radius: 4px;
}

.input:focus-visible {
	outline: 1px solid var(--color-brand-blue);
}

@supports not selector(:focus-visible) {
	.input:focus {
		outline: 1px solid var(--color-brand-blue);
	}
}

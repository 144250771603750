.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--z-header);
  padding-block: 24px;
  background-color: var(--color-white-95);
}

.header__navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .header__navbar {
    flex-wrap: nowrap;
    justify-content: start;
  }
}

.header__navbar-menu {
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .header__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 992px) {
  .header__navbar-menu {
    width: 100%;
  }
}

.header__navbar-menu-item {
  border-bottom: 1px solid var(--color-black);
}

@media screen and (min-width: 992px) {
  .header__navbar-menu-item {
    border-bottom: none;
  }
}

.header__navbar.collapse .header__navbar-menu {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.8s;
}

@media screen and (min-width: 992px) {
  .header__navbar.collapse .header__navbar-menu {
    max-height: none;
    overflow-y: visible;
  }
}

.header__navbar-menu {
  max-height: 1000px;
  transition: max-height 0.8s;
}

.header__logo-link {
  display: block;
  width: fit-content;
}

.burger,
.burger-lines {
  display: block;
}

.burger {
  height: 36px;
  width: 36px;
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger .burger-lines:after {
  left: 0;
  top: -12px;
}

.burger .burger-lines:before {
  left: 12px;
  top: 12px;
}

.burger:after {
  content: '';
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}

.burger .burger-lines {
  top: 50%;
  margin-top: -1.5px;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  border-radius: 3px;
  background-color: var(--color-black);
  height: 3px;
  position: absolute;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.burger .burger-lines:after {
  left: 0;
  top: -12px;
}

.burger .burger-lines:before {
  left: 12px;
  top: 12px;
}

.burger.burger-slip .burger-lines:after,
.burger.burger-slip .burger-lines:before {
  width: 24px;
}

.burger.burger-slip .burger-lines,
.burger.burger-slip .burger-lines:after,
.burger.burger-slip .burger-lines:before {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.burger.burger-slip.open .burger-lines {
  -webkit-transform: translateX(-24px);
  -ms-transform: translateX(-24px);
  transform: translateX(-24px);
  background-color: transparent;
}

.burger.burger-slip.open .burger-lines:before,
.burger.burger-slip.open .burger-lines:after {
  left: 0.5em;
  top: 0px;
}

.burger.burger-slip.open .burger-lines:before {
  -webkit-transform: translateX(24px) rotate(135deg);
  -ms-transform: translateX(24px) rotate(135deg);
  transform: translateX(24px) rotate(135deg);
}

.burger.burger-slip.open .burger-lines:after {
  -webkit-transform: translateX(24px) rotate(-135deg);
  -ms-transform: translateX(24px) rotate(-135deg);
  transform: translateX(24px) rotate(-135deg);
}

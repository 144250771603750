:root {
  --primary-font-family: 'Helvetica Neue', sans-serif;
  --secondary-font-family: 'Montserrat Alternates', sans-serif;
  --tertiary-font-family: 'Montserrat', serif;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;

  --color-brand-green: #385738;
  --color-accent: #f0803c;
  --color-accent-dark: #ed6919;
  --color-primary-brown: #2b2212;
  --color-background-green: #bccf60;
  --color-background-grey: #f4f3f7;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-95: #fffffff2;
  --color-red: #fe4545;

  --z-local-first: 1;
  --z-local-second: 2;
  --z-local-third: 3;
  --z-header: 9999;
  --z-modal: 10000;
}

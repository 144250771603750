.home-page .page-cover {
  position: relative;
  overflow: hidden;
  padding: 35px 0 50px;
}

@media screen and (min-width: 768px) {
  .home-page .page-cover {
    padding: 96px 0 138px;
  }
}

.home-page .page-cover__background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: var(--z-local-first);
}

@media screen and (min-width: 768px) {
  .home-page .page-cover__background {
    height: auto;
  }
}

.home-page .page-cover__background-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.7;
}

.home-page .page-cover__background-image-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 100%);
}

.home-page .page-cover__content {
  position: relative;
  z-index: var(--z-local-second);
}

.home-page .page-cover__title {
  text-align: center;
  margin-inline: auto;
  max-width: 360px;
  margin-bottom: 24px;
}

@media screen and (min-width: 576px) {
  .home-page .page-cover__title {
    max-width: 730px;
  }
}

@media screen and (min-width: 768px) {
  .home-page .page-cover__title {
    margin-bottom: 32px;
  }
}

.home-page .page-cover__description {
  text-align: center;
  margin-inline: auto;
  max-width: 360px;
  margin-bottom: 24px;
}

@media screen and (min-width: 576px) {
  .home-page .page-cover__description {
    max-width: 560px;
  }
}

@media screen and (min-width: 768px) {
  .home-page .page-cover__description {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 992px) {
  .home-page .services-section__content-description {
    max-width: 520px;
  }
}

.home-page .case-study__image-container {
  position: relative;
}

.home-page .case-study__image {
  position: relative;
  width: 100%;
  max-width: 875px;
  margin-left: 0;
  height: 100%;
  margin-inline: auto;
}

@media screen and (min-width: 1200px) {
  .home-page .case-study__image {
    margin-left: -170px;
    width: auto;
  }
}

@media screen and (min-width: 1400px) {
  .home-page .case-study__image-container {
    height: 492px;
  }

  .home-page .case-study__image {
    margin-left: -180px;
  }
}

.home-page .case-study__text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-page .contact-us-form {
  height: 765px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.home-page .contact-us-form__image-container {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.home-page .contact-us-form__image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Helvetica Neue - regular */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/HelveticaNeue-Regular.woff2") format("woff2"),
		url("../../fonts/HelveticaNeue-Regular.woff") format("woff"),
		url("../../fonts/HelveticaNeue-Regular.ttf") format("ttf"),
		url("../../fonts/HelveticaNeue-Regular.eot") format("eot");
}

/* Helvetica Neue - medium */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 500;
	src: url("../../fonts/HelveticaNeue-Medium.woff2") format("woff2"),
		url("../../fonts/HelveticaNeue-Medium.woff") format("woff"),
		url("../../fonts/HelveticaNeue-Medium.ttf") format("ttf"),
		url("../../fonts/HelveticaNeue-Medium.eot") format("eot");
}

/* Helvetica Neue - bold */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/HelveticaNeue-Bold.woff2") format("woff2"),
		url("../../fonts/HelveticaNeue-Bold.woff") format("woff"),
		url("../../fonts/HelveticaNeue-Bold.ttf") format("ttf"),
		url("../../fonts/HelveticaNeue-Bold.eot") format("eot");
}

/* Montserrat Alternates - bold */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Montserrat Alternates";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/MontserratAlternates-Bold.woff2") format("woff2"),
		url("../../fonts/MontserratAlternates-Bold.woff") format("woff"),
		url("../../fonts/MontserratAlternates-Bold.ttf") format("ttf"),
		url("../../fonts/MontserratAlternates-Bold.eot") format("eot");
}

/* Montserrat - bold */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/Montserrat-Bold.woff2") format("woff2"),
		url("../../fonts/Montserrat-Bold.woff") format("woff"),
		url("../../fonts/Montserrat-Bold.ttf") format("ttf"),
		url("../../fonts/Montserrat-Bold.eot") format("eot");
}

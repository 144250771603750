.section {
  padding: 100px 0;
  scroll-margin-top: 100px;
}

.section--no-bottom-padding {
  padding-bottom: 0;
}

.section--with-bottom-margin {
  margin-bottom: 100px;
}

.section--grey {
  background-color: var(--color-background-grey);
}

.icon {
  display: inline-block;
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
  max-width: none;
  background-position: center;
  background-repeat: no-repeat;
}

.icon--linkedin {
  width: 32px;
  height: 32px;
  background-image: url('../../icons/linkedin-logo.svg');
}

.icon--instagram {
  width: 32px;
  height: 32px;
  background-image: url('../../icons/instagram-logo.svg');
}

.icon--facebook {
  width: 32px;
  height: 32px;
  background-image: url('../../icons/facebook-logo.svg');
}

.icon--cross {
  width: 32px;
  height: 32px;
  background-image: url('../../icons/cross.svg');
}

.icon--cross-red {
  width: 32px;
  height: 32px;
  background-image: url('../../icons/cross-red.svg');
}

.icon--cancel {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/cancel.svg');
}

.icon--check-circle {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/check-circle.svg');
}

.icon--star-filled {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/star-filled.svg');
}

.icon--clock {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/clock-1.svg');
}

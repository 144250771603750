.footer {
  padding-block: 50px;
  background: var(--color-background-grey);
}

@media screen and (min-width: 768px) {
  .footer .footer-description {
    max-width: 340px;
  }
}

@media screen and (min-width: 992px) {
  .footer .footer-description {
    max-width: 415px;
  }
}

.form__container .form {
	display: block;
}

.form__container .form__success-message,
.form__container .form__fail-message {
	display: none;
}

.form__container.success .form,
.form__container.error .form {
	display: none;
}

.form__container.success .form__success-message {
	display: block;
}

.form__container.error .form__fail-message {
	display: block;
}

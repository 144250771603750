.dropdown-block {
  position: relative;
}

.dropdown-block .dropdown-block__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-block .dropdown-block__arrow {
  transition: transform 0.8s cubic-bezier(0, 0.6, 0.2, 1) 0ms;
}

.dropdown-block.active .dropdown-block__arrow {
  transform: rotate(180deg);
}

.dropdown-block .dropdown-block__list {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.8s cubic-bezier(0, 0.6, 0.2, 1) 0ms, background-color 0.8s;
}

.dropdown-block.active .dropdown-block__list {
  max-height: 1000px;
  transition: max-height 0.8s ease-in 0ms, background-color 0.8s;
}

.dropdown-block .dropdown-block__item {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dropdown-block--header .dropdown-block__list {
    position: absolute;
    white-space: nowrap;
    overflow-x: visible;
    max-width: none;
    width: auto;
    background-color: var(--color-white-95);
    transform: translateY(21px);
  }

  .dropdown-block__item {
    max-width: none;
    transition: opacity 0.8s;
  }

  .dropdown-block__item:focus-visible {
    opacity: 0.8;
  }
}

@media screen and (min-width: 992px) {
  .dropdown-block--header .dropdown-block__list {
    transform: translateY(29px);
  }
}

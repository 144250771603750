body {
  font-family: var(--primary-font-family);
  font-style: normal;
  color: var(--color-primary-brown);
}

.heading,
.subheading,
.paragraph,
.label {
  margin: 0;
}

.heading {
  font-weight: var(--font-weight-bold);
  font-size: 32px;
  line-height: 1.22; /* 39.01px */
}

.heading--extra-small {
  font-size: 24px;
  line-height: 1.43; /* 34.32px */
  letter-spacing: -0.02em;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 62px;
    line-height: 1.27; /* 84px */
    letter-spacing: -0.02em;
  }

  .heading--big {
    font-size: 80px;
  }

  .heading--small,
  .heading--extra-small {
    font-size: 28px;
    line-height: 1.43; /* 40px */
    letter-spacing: -0.02em;
  }
}

.subheading {
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  line-height: 1.22; /* 21.98px */
}

@media screen and (min-width: 768px) {
  .subheading {
    font-size: 28px;
    line-height: 1.43; /* 40px */
    letter-spacing: -0.02em;
  }
}

.paragraph {
  font-weight: var(--font-weight-regular);
  font-size: 18px;
  line-height: 1.44; /* 26px */
}

.paragraph--bold {
  font-weight: var(--font-weight-bold);
}

.paragraph--big {
  font-size: 22px;
  line-height: 1.27; /* 28px */
}

.paragraph--biggest {
  font-size: 26px;
}

.label {
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 1.22; /* 19.5px */
  letter-spacing: 0.02em;
}

.label--branded {
  font-family: var(--secondary-font-family);
  letter-spacing: normal;
}

.special-number {
  font-weight: var(--font-weight-bold);
  font-size: 32px;
  line-height: 1.22; /* 39.01px */
}

@media screen and (min-width: 992px) {
  .special-number {
    font-size: 66px;
    line-height: 1.27; /* 84px */
  }
}

@media screen and (min-width: 768px) {
  .special-number--small {
    font-size: 36px;
    line-height: 1.27; /* 46px */
  }
}

.list--marked .list__item {
	display: flex;
	position: relative;
}

.list--marked .list__item::before {
	display: block;
	content: url("../../icons/check-circle.svg");
	width: 100%;
	max-width: 24px;
	height: 24px;
	margin-right: 12px;
}

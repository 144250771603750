.modal {
	position: relative;
	background: var(--color-background-grey);
	width: 100%;
	z-index: var(--z-local-second);
}

.modal__close-btn {
	position: absolute;
	top: 12px;
	right: 12px;
}

.modal__container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-modal);
	width: 100%;
	height: 100%;
	transform: scale(0);
}

.modal__background {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: var(--z-local-first);
	padding: 0 16px;
}

.modal__container.active {
	transform: scale(1);
}

.modal__container.active .modal__background {
	background: rgba(0, 0, 0, 0);
	animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal__container.active .modal__background .modal {
	opacity: 0;
	animation: scaleUpMoveDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal__container.out {
	animation: quickScaleDown 0s 0.5s linear forwards;
}

.modal__container.out .modal__background {
	animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal__container.out .modal__background .modal {
	animation: scaleDownMoveTop 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal--team-member {
	max-width: 560px;
}

.modal--team-member .modal__photo-container {
	padding-top: 30px;
	margin-bottom: 30px;
	height: 226px;
	background: var(--color-white);
}

.modal--team-member .modal__photo {
	max-height: 100%;
	margin-inline: auto;
}

.modal--team-member .modal__text-container {
	padding: 0 16px 16px;
}

@media screen and (min-width: 576px) {
	.modal--team-member .modal__text-container {
		padding: 0 30px 30px;
	}
}
